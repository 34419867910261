import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { BREAKPOINTS, LAYOUT, ANIMATION } from '../../styles/cssVariables'
import { useStaticQuery, graphql } from 'gatsby'

import Container from '../layout/container'
import sun from '../../images/backgrounds/Ellipse.png'
import wave from '../../images/icons/wave_double_size.svg'
import { isAbsoluteUrl } from '../../utils/utils'
import IconLinkArrowSvg from '../../images/icons/link-arrow.svg'

const Wrapper = styled.section`
  position: relative;
  padding: 65px 0 65px;

  background: linear-gradient(180deg, rgba(0, 97, 99, 0.45) 0%, rgba(255, 252, 250, 0) 100%);  
  margin-bottom: 95px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    // padding-top: 35px;
    padding-bottom: 50px;
    // margin-bottom: 50px;
    padding-top: 50px;

  }
  @media (max-width: 770px) {
    padding-top: 0;
  }
  @media (max-width: 650px) {
    padding-top: 30px;
  }



  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-bottom: 0;
   
    margin-bottom: 60px;
  }



`
const Sun = styled.img`
  display: none;
 
  @media (max-width: 650px) {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 15%;
  }
  @media (max-width: 500px) {
    width: 90%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    bottom: 30%;
  }
  @media (max-width: 425px) {
  
    bottom: 47%;
  }
`

const SecondaryHeadingContainer = styled(Container)`

@media (max-width: ${BREAKPOINTS.medUp}px) {
  padding: 0 ${LAYOUT.gutterPage / 2}px;

}

@media (max-width: 800px) {
  padding-left: 30px;
  padding-right: 30px;
}




 > div {
   display: grid;
   grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  background-image: url('${sun}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
 }

@media (max-width: 650px) {
  
  > div {
    grid-gap: 0;
    background-image: none;
  }
}

// @media (max-width: 414px) {
  
//   > div {
//     grid-gap: 15px;
//   }
// }

`

const SecondaryHeading = styled.h2`
text-align: center;
  z-index: 998;
  width: 70%;
  padding-top: 90px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1366px) {
    width: 60%;
  }
  @media (max-width: 1259px) {
    padding-top: 110px;
   
  }
  @media (max-width: 1245px) {
    width: 70%;
   
  }
  @media (max-width: 900px) {
    padding-top: 50px;
   
  }

  @media (max-width: 770px) {
    width: 90%;
  }
  @media (max-width: 900px) {
    padding-top: 20px;
   
  }
  @media (max-width: 650px) {
    padding-bottom: 20px;
    padding-top: 30px;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding-top: 20px;
  }

 
`
const SecondaryBody = styled.p`

text-align: center;
z-index: 997;
width: 50%;
margin-left: auto;
margin-right: auto;

@media (max-width: 1300px) {
  width: 70%;
}



@media (max-width: 770px) {
  width: 90%;
}


@media (max-width: 544px) {

  width: 100%;

}

`
const WaveContainer = styled(Container)`
  width: 100%;
  padding: 0;
  z-index: 990;
  @media (max-width: 1300px) {
    margin-top: -5%;
  }

  @media (max-width: 977px) {
    margin-top: 0;
  }
  @media (max-width: 500px) {
    margin-bottom: 30px;
  }
`
const Wave = styled(wave)`
  width: 100%;
 
  @media (max-width: 977px) {
    height: 60%;
  }

`

/**
 * Displays clickable popular search terms, visually styled as tags.
 * Fetches data from the Site data type in the CMS to populate these popular search terms.
 */
const HeroSecondaryLanding = (props) => {
 
  return (
    <Wrapper>
       <Sun src={sun} alt='decorative sun vector graphic' />
      <SecondaryHeadingContainer flex={false} width={12}>
        {props.title && <SecondaryHeading>{props.title}</SecondaryHeading>}
        {props.body && <SecondaryBody>{props.body}</SecondaryBody>}
        <WaveContainer width={12}>
          <Wave alt='decorative wave vector graphic' />
        </WaveContainer>
      </SecondaryHeadingContainer>
     
      
    </Wrapper>
  )
}

HeroSecondaryLanding.propTypes = {
  /** Optional heading */
  title: PropTypes.string,
  body: PropTypes.string,
}


export default HeroSecondaryLanding
