import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Button from './button'
import { BREAKPOINTS, ANIMATION } from '../../styles/cssVariables'
import { isAbsoluteUrl } from '../../utils/utils'

const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${(props) => props.layout === 'small' && 'align-items: center;'}

  ${(props) =>
    props.layout === 'large'
      ? 'padding: 36px 40px;'
      : 'padding: 34px 14px 28px;'}
  border: 1px solid rgba(229, 234, 244, 0.5);
  border-radius: 4px;

  background-color: #fff;

  ${(props) => props.layout === 'small' && 'text-align: center;'}
  box-shadow: 0px 10px 20px rgba(15, 24, 44, 0.1);

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 27px 30px 32px;
  }
`

const Icon = styled.img`
  height: 65px;
  width: 65px;
  ${(props) =>
    props.layout === 'large' ? 'margin-bottom: 30px;' : 'margin-bottom: 20px;'}

  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 0px;
    transform: scale(0.74);
    transform-origin: top left;
  }
`

const CardHeading = styled.h3`
  ${(props) =>
    props.layout === 'large' ? 'margin-bottom: 16px;' : 'margin-bottom: 28px;'}

  ${(props) => props.layout === 'small' && 'font-size: 16px;'}

  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 12px;
  }
`

const CardBody = styled.p`
  ${(props) =>
    props.layout === 'large' ? 'margin-bottom: 48px;' : 'margin-bottom: 24px;'}
  ${(props) => props.layout === 'small' && 'font-size: 14px;'} @media
    (max-width: ${BREAKPOINTS.sml}px) {
    ${(props) =>
      props.layout === 'large' ? 'margin-bottom: 24px;' : 'margin-bottom: 15px;'}
  }
`

const Cta = styled(Button)`
  margin-top: auto;
  color: var(--color-skobeloff);


  ${(props) =>
    !props.$ctaDestination &&
    `
    pointer-events: none;

    svg {
      display: none;
    }
  `}
`



/**
 * A small card that shows an icon, a short piece of text and a CTA.
 * Appears throughout the site e.g. homepage under banner, contact page FAQs etc.
 * Small layout has less padding, less margin between elements and content is centrally aligned.
 */
const InfoCard = (props) => {
  return (
    <Card className={props.className} layout={props.layout.toLowerCase()}>
      <Icon alt={props.icon.file.fileName} src={props.icon.file.url} />
      <CardHeading layout={props.layout.toLowerCase()}>
        {props.heading}
      </CardHeading>
      <CardBody layout={props.layout.toLowerCase()}>{props.body}</CardBody>
      {props.ctaText && (
        <Cta $ctaDestination={props.ctaDestination}>
          {props.ctaText ? (
            isAbsoluteUrl(props.ctaDestination) ? (
              <a href={props.ctaDestination} target="__blank" rel="nofollow">
                {props.ctaText}
              </a>
            ) : (
              <Link to={props.ctaDestination}>{props.ctaText}</Link>
            )
          ) : (
            props.ctaText
          )}
        </Cta>
      )}
    </Card>
  )
}

InfoCard.propTypes = {
  /** Smaller long text in the middle of the card */
  body: PropTypes.string,
  /** styled-components className prop */
  className: PropTypes.string,
  /** Link destination */
  ctaDestination: PropTypes.string,
  /** Link text */
  ctaText: PropTypes.string,
  /** Larger, heavier text at the top of the card */
  heading: PropTypes.string,
  /** Icon to render at the top of the card */
  icon: PropTypes.object,
  /** Small layout has less padding, less margin between elements and content is centrally aligned */
  layout: PropTypes.oneOf(['Small', 'Large', 'small', 'large']),
}

InfoCard.defaultProps = {
  layout: 'large',
}

export default InfoCard
