import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import SvgFoundingMember from '../../images/icons/founding-member.svg'
import { BREAKPOINTS, ANIMATION } from '../../styles/cssVariables'
import IconLinkArrowSvg from '../../images/icons/link-arrow.svg'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 38px;
  padding-bottom: 38px;
  border: 1px solid rgba(229, 234, 244, 0.5);
  border-radius: 4px;

  background-color: #fff;

  filter: drop-shadow(0px 10px 20px rgba(15, 24, 44, 0.1));
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 870px) {
    width: 85%;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    width: 75%;
  }
  @media (max-width: 740px) {
    width: 80%;
  }
  @media (max-width: 595px) {
    width: 90%;
  }
  @media (max-width: 528px) {
    width: 95%;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-top: 28px;
    padding-bottom: 28px;
    width: 80%;
  }
  @media (max-width: 470px) {
    width: 95%;
  }
  @media (max-width: 340px) {
    width: 100%;
  }
`
const ContainerCardTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`

const CardTitle = styled.h3`
    display: inline-block; 
    font-size: 15px;
`
const ContainerTop = styled.div`
    background-color: rgba(161, 229, 207, 0.1);
    margin-bottom: 30px;
`

const ContainerSessionTitle = styled.div`
    padding-left: 28px;
    padding-right: 28px;
    padding-top: 38px;
    display: flex;
`

const SessionTitle = styled.h4`
    font-size: 14px;
    max-width: 75%;
    margin-bottom: 24px;
`
const ConatinerGuestName = styled.div`
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 38px;

`

const GuestName = styled.p`
 
    font-style: italic;
    font-size: 14px;
    max-width: 75%;
`

const DateTime = styled.p`
 margin-left: 28px;
 margin-top: auto;
 margin-bottom: 30px;
 padding-top: 7px;
 padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
 border-radius: 4px;
 width: fit-content;
 font-size: 14px;
 background-color: rgba(249, 165, 43, 0.5);
 color: var(--color-heading);
`
const Excerpt = styled.p`
    padding-left: 28px;
    padding-right: 28px;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: 40px;
`

const ProfilePic = styled(GatsbyImage)`
  width: 55px;
  height: 55px;
  margin-left: auto;
  border-radius: 50% !important;
  img {
    border-radius: 50% !important;
  }
`

const FounderIcon = styled(SvgFoundingMember)`
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 0.5em;
`


const ContainerContact = styled.div`
  display: flex;
  justify-content: center;
  
  margin-top: auto;

  a {
    font-size: 14px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 22px;
  }
`
const CTAButton = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 15px;
  border: 1px solid var(--color-heading);
  background-color: var(--color-heading) !important;
  color: #fff !important;
  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff !important;
    color: var(--color-heading) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
`

const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`

const LiveTrainingCard = (props) => {
  return (
    <Card>
        <ContainerCardTitle>
            <FounderIcon />
            <CardTitle>{props.cardTitle}</CardTitle>
        </ContainerCardTitle>
      
       <ContainerTop>     
            <ContainerSessionTitle>
                <SessionTitle>{props.sessionTitle}</SessionTitle>
                <ProfilePic alt={props.picTitle} image={props.profilePic} />
            </ContainerSessionTitle>
            <ConatinerGuestName><GuestName>{props.guestName}</GuestName></ConatinerGuestName>
        </ContainerTop>

      <DateTime>{props.dateTime}</DateTime>

      <Excerpt>{props.excerpt}</Excerpt>

    
      <ContainerContact>
        <CTAButton href={props.ctaDestination} target="__blank"
                        rel="nofollow">
          {props.ctaText}
          <Arrow />
        </CTAButton>
      </ContainerContact>
    </Card>
  )
}



export default LiveTrainingCard 
