import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import { BREAKPOINTS, ANIMATION, LAYOUT } from '../styles/cssVariables'
import Container from './layout/container'

const FeatureOfferingsSection = styled.section`
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }
  
`

const FeatureOfferingsFlex = styled(Container)`

`

const FeatureOfferingsTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-bottom: 27px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 20px;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 15px;
    
  }
`

const FeatureOfferingsTopInfoBar = styled.div`
    background-color: rgba(161, 229, 207, 0.1);
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding-top: 40px;
    padding-bottom: 40px;
    @media (max-width: ${BREAKPOINTS.medUp}px) {
      flex-direction: column;
      align-content: center;
      justify-content: center;
      padding-top: 0;
      padding-bottom: 0;
      margin-left: unset;
      margin-right: unset; 
      width: 40%;
      margin-left: auto;
      margin-right: auto;
      background-color: transparent;
      // padding-top: 0;
    }
    @media (max-width: 839px) {
      width: 50%;
    }
    @media (max-width: 545px) {
      width: 60%;
    }
`

const FeatureOfferingsTopCol = styled.div`
  
  flex-basis: 0;

  flex-grow: 1;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  display: block;
  padding: 0;


    &:first-child {
      border-right: 1px solid #d7dce2;
    }
    &:nth-child(2) {
      border-right: 1px solid #d7dce2;
    }
    @media (max-width: ${BREAKPOINTS.medUp}px) {
      background-color: rgba(161, 229, 207, 0.1);
      padding: 30px;
      margin-top: 15px;
      margin-bottom: 15px;
      border-radius: 4px;
      width: 100%;
      &:first-child {
        border-right: none;
      }
      &:nth-child(2) {
        border-right: none;
      }
      border: 1px solid #d7dce2;
      &:first-child {
        border-right: 1px solid #d7dce2;
       
      }
      &:nth-child(2) {
        border-right: 1px solid #d7dce2;
      }
    }
    @media (max-width: ${BREAKPOINTS.smlUp}px) {
      padding: 20px;
    }
  

`
const FeatureOfferingsTopEmojis = styled.p`

`


const FeatureOfferingsTopTitle = styled.h3`
    margin-bottom: 15px;
    @media (max-width: ${BREAKPOINTS.medUp}px) {
      margin-bottom: 10px;
    }
  
`

const FeatureOfferingsTopBody = styled.p`

  margin-block-start: 0;
  margin-block-end: 0;
`

const FeatureOfferingsBottomInfoBar = styled.div`
  
`

const FeatureOfferingsBottomEmojis = styled.p`
    display: inline-block;
    
`

const FeatureOfferingsBottomRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  column-gap: 33px;
  @media (max-width: 1200px) {
    column-gap: 20px;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    grid-template-columns: auto 1fr;
    margin-left: auto;
    margin-right: auto;
   
  }
  
  @media (max-width: ${BREAKPOINTS.sml}px) {
    column-gap: 15px;
    padding-left: 4%;
    padding-right: 4%;
  }
  @media (max-width: 320px) {
    column-gap: 10px;
    padding-left: 0;
    padding-right: 0;
  }
`

const FeatureOfferingsBottomTitle = styled.h3`
  margin-top: 60px;
  margin-bottom: 35px;
  margin-left: 50px;
  @media (max-width: 1200px) {
    margin-left: 36px;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-left: 0;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {

    margin-top: 20px;
    margin-bottom: 25px;
  }
`

const FeatureOfferingsBottomItems = styled.p`
  margin-block-start: 0;
  margin-block-end: 0;
  display: inline-block;
  margin-bottom: 30px;
  
`

const FeatureOfferingsButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: ${BREAKPOINTS.tbl}px) {
      flex-direction: column;
      align-items: center;
    }
`

const FeatureOfferingsButtonPrimary = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 60px;
  border: 1px solid var(--color-heading);
  background-color: var(--color-heading) !important;
  color: #fff !important;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff !important;
    color: var(--color-heading) !important;
  }
  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }

  
`


const FeatureOfferingsButtonSecondary = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 60px;
  margin-left: 20px;
  background-color: #fff;
  border: 1px solid var(--color-skobeloff);
  color: var(--color-skobeloff);
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;


  &:hover {
    color: var(--color-white) !important;
    background-color: var(--color-skobeloff);
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;

  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-left: 0;

    border: 1px solid;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }
  
`


const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`



const FeatureOfferings = (props) => {
  return (
    <FeatureOfferingsSection>
    <FeatureOfferingsFlex width={12}>
      <FeatureOfferingsTitle>{props.heading}</FeatureOfferingsTitle>

        <FeatureOfferingsTopInfoBar>
          
            <FeatureOfferingsTopCol>
             <FeatureOfferingsTopEmojis className="first-top-emoji">{props.TopEmoji1}</FeatureOfferingsTopEmojis>

                <FeatureOfferingsTopTitle>{props.TopInfoTitle1}</FeatureOfferingsTopTitle>
             
                <FeatureOfferingsTopBody>{props.TopInfoBody1}</FeatureOfferingsTopBody>
            </FeatureOfferingsTopCol>
           

              <FeatureOfferingsTopCol>
                <FeatureOfferingsTopEmojis>{props.TopEmoji2}</FeatureOfferingsTopEmojis>

                  <FeatureOfferingsTopTitle>{props.TopInfoTitle2}</FeatureOfferingsTopTitle>
            
                  <FeatureOfferingsTopBody>{props.TopInfoBody2}</FeatureOfferingsTopBody>
              </FeatureOfferingsTopCol>
           

            <FeatureOfferingsTopCol>
              <FeatureOfferingsTopEmojis>{props.TopEmoji3}</FeatureOfferingsTopEmojis>

                <FeatureOfferingsTopTitle>{props.TopInfoTitle3}</FeatureOfferingsTopTitle>
            
                <FeatureOfferingsTopBody>{props.TopInfoBody3}</FeatureOfferingsTopBody>
            </FeatureOfferingsTopCol>
        </FeatureOfferingsTopInfoBar>

        <FeatureOfferingsBottomTitle>{props.BottomInfoTitle}</FeatureOfferingsBottomTitle>

        <FeatureOfferingsBottomInfoBar>

            <FeatureOfferingsBottomRow>
            <FeatureOfferingsBottomEmojis>
                {props.Emoji1}
              </FeatureOfferingsBottomEmojis>
              <FeatureOfferingsBottomItems>{props.BottomInfoFirst}</FeatureOfferingsBottomItems>
              <FeatureOfferingsBottomEmojis>
                {props.Emoji2}
              </FeatureOfferingsBottomEmojis>
              <FeatureOfferingsBottomItems>{props.BottomInfoSecond}</FeatureOfferingsBottomItems>
            </FeatureOfferingsBottomRow>

            <FeatureOfferingsBottomRow>
              <FeatureOfferingsBottomEmojis>
                  {props.Emoji3}
                </FeatureOfferingsBottomEmojis>
              <FeatureOfferingsBottomItems>{props.BottomInfoThird}</FeatureOfferingsBottomItems>
              <FeatureOfferingsBottomEmojis>
                  {props.Emoji4}
                </FeatureOfferingsBottomEmojis>
                <FeatureOfferingsBottomItems>{props.BottomInfoFourth}</FeatureOfferingsBottomItems>
              </FeatureOfferingsBottomRow>
              <FeatureOfferingsBottomRow>
                <FeatureOfferingsBottomEmojis>
                  {props.Emoji5}
                </FeatureOfferingsBottomEmojis>
                <FeatureOfferingsBottomItems>{props.BottomInfoFifth}</FeatureOfferingsBottomItems>
                <FeatureOfferingsBottomEmojis>
                  {props.Emoji6}
              </FeatureOfferingsBottomEmojis>
                <FeatureOfferingsBottomItems>{props.BottomInfoSixth}</FeatureOfferingsBottomItems>
           </FeatureOfferingsBottomRow>
        </FeatureOfferingsBottomInfoBar>

            <FeatureOfferingsButtonContainer>
                <FeatureOfferingsButtonPrimary href={props.firstCtaDestination}
                        target="__blank"
                        rel="nofollow" >
    
                        {props.firstCtaText}
             
                    <Arrow /> 
                </FeatureOfferingsButtonPrimary>
                {props.secondCtaText &&
                  <FeatureOfferingsButtonSecondary     
                  href={props.secondCtaDestination}
                  target="__blank"
                  rel="nofollow">
                     
                          {props.secondCtaText}
        
                  </FeatureOfferingsButtonSecondary>
                 }
        </FeatureOfferingsButtonContainer>
    </FeatureOfferingsFlex>
    </FeatureOfferingsSection>
  )
}



export default FeatureOfferings
