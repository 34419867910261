import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { BREAKPOINTS, LAYOUT, ANIMATION } from '../styles/cssVariables'
import Layout from '../components/layout/layout'
import SEO from '../components/layout/seo'
import Container from '../components/layout/container'
import LogoGrid from '../components/logoGrid'
import HeroSecondaryLanding from '../components/ui-kit/heroSecondaryLanding'
import InfoCard from '../components/ui-kit/infoCard'
import slugify from 'slugify'
import { isAbsoluteUrl } from '../utils/utils'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import FeatureList from '../components/featureList'
import FeatureOfferings from '../components/featureOfferings'
import LiveTrainingCard from '../components/ui-kit/liveTrainingCard'
import FeatureNumberedGrid from '../components/numberedGrid'
import { Link } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Hero from '../components/hero'


const FeaturesContainer = styled(Container)`
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }

`

const FeaturesHeading = styled.h2`
  margin-bottom: 40px;
  text-align: ${(props) => props.textAlign.toLowerCase()};
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 30px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 20px;
  }
`

const FeaturesCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-gap: 22px 30px;

  @media (max-width: ${BREAKPOINTS.med}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    grid-template-columns: 1fr;
  }
`

const FeaturesBody = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-bottom: 50px;
  p {
    text-align: center;
    margin-bottom: 25px;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }
`

const InfoCardCentred = styled(InfoCard)`
  flex: 1;

  @media (min-width: ${BREAKPOINTS.smlUp}px) {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    > span {
      transform-origin: center;
    }
  }
`

const InfoGridButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: ${BREAKPOINTS.tbl}px) {
      flex-direction: column;
      align-items: center;
    }
`
const InfoGridButton = styled.a`
  box-sizing: border-box;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 60px;
  border: 1px solid var(--color-heading);
  background-color: var(--color-heading);
  color: #fff !important;

  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff;
    color: var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
    @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }
`

const InfoGridSecondaryButton = styled.a`
  box-sizing: border-box;  
  width: fit-content;
  position: relative;

  display: flex;
  align-items: center;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 60px;
  margin-left: 20px;
  background-color: #fff;
  border: 1px solid var(--color-skobeloff);
  color: var(--color-skobeloff);
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;


  &:hover {
    color: var(--color-white) !important;
    background-color: var(--color-skobeloff);
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;

  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-left: 0;

    border: 1px solid;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }
`


const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`

const FeatureListWrapper = styled.section`

    margin-bottom: 200px;
    @media (max-width: ${BREAKPOINTS.med}px) {
      margin-bottom: 175px;
    }
    @media (max-width: ${BREAKPOINTS.tbl}px) {
      margin-bottom: 150px;
    }
    @media (max-width: ${BREAKPOINTS.sml}px) {
      margin-bottom: 100px;
    }
    > div div div div div picture img {
      border-radius: 4px;
    }

`
const FeatureListTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    width: 100%;
  }
`

const LogoListWrapper = styled.section`
  padding-bottom: 250px;

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-bottom: 100px;
  }
`
const LogoListContainer = styled.div`
  padding: 0 ${LAYOUT.gutterPage}px;
  box-sizing: border-box;
  width: 100%;
  max-width: ${LAYOUT.pageWidth}px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 0 ${LAYOUT.gutterPage / 2}px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 0 ${LAYOUT.gutterPageMobile}px;
  }
`
const FeatureLiveTrainingsSection = styled.section`
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }
  
`

const FeatureLiveTrainingsContainer = styled(Container)`
  
`

const LiveTrainingTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    width: 100%;
  }
`

const LiveTrainingCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 35px;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    grid-template-columns: 1fr;
    row-gap: 35px;
  }
`

const LiveTrainingCTA = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 60px;
  background-color: var(--color-heading);
  color: #fff !important;
  border: 1px solid var(--color-heading);
  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff;
    color: var(--color-heading) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
`
const SectionContainer = styled.section`
`
const FeatureOfferingsSection = styled.section`
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }
  
`

const FeatureOfferingsFlex = styled(Container)`

`

const FeatureOfferingsTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-bottom: 27px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 20px;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 15px;
    
  }
`

const FeatureOfferingsTopInfoBar = styled.div`
    background-color: rgba(161, 229, 207, 0.1);
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding-top: 40px;
    padding-bottom: 40px;
    @media (max-width: ${BREAKPOINTS.medUp}px) {
      flex-direction: column;
      align-content: center;
      justify-content: center;
      padding-top: 0;
      padding-bottom: 0;
      margin-left: unset;
      margin-right: unset; 
      width: 40%;
      margin-left: auto;
      margin-right: auto;
      background-color: transparent;
      // padding-top: 0;
    }
    @media (max-width: 839px) {
      width: 50%;
    }
    @media (max-width: 545px) {
      width: 60%;
    }
`

const FeatureOfferingsTopCol = styled.div`
  
  flex-basis: 0;

  flex-grow: 1;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  display: block;
  padding: 0;


    &:first-child {
      border-right: 1px solid #d7dce2;
    }
    &:nth-child(2) {
      border-right: 1px solid #d7dce2;
    }
    @media (max-width: ${BREAKPOINTS.medUp}px) {
      background-color: rgba(161, 229, 207, 0.1);
      padding: 30px;
      margin-top: 15px;
      margin-bottom: 15px;
      border-radius: 4px;
      width: 100%;
      &:first-child {
        border-right: none;
      }
      &:nth-child(2) {
        border-right: none;
      }
      border: 1px solid #d7dce2;
      &:first-child {
        border-right: 1px solid #d7dce2;
       
      }
      &:nth-child(2) {
        border-right: 1px solid #d7dce2;
      }
    }
    @media (max-width: ${BREAKPOINTS.smlUp}px) {
      padding: 20px;
    }
  

`
const FeatureOfferingsTopEmojis = styled.p`

`


const FeatureOfferingsTopTitle = styled.h3`
    margin-bottom: 15px;
    @media (max-width: ${BREAKPOINTS.medUp}px) {
      margin-bottom: 10px;
    }
  
`

const FeatureOfferingsTopBody = styled.p`

  margin-block-start: 0;
  margin-block-end: 0;
`

const FeatureOfferingsBottomInfoBar = styled.div`
  
`

const FeatureOfferingsBottomEmojis = styled.p`
    display: inline-block;
    
`

const FeatureOfferingsBottomRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  column-gap: 33px;
  @media (max-width: 1200px) {
    column-gap: 20px;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    grid-template-columns: auto 1fr;
    margin-left: auto;
    margin-right: auto;
   
  }
  
  @media (max-width: ${BREAKPOINTS.sml}px) {
    column-gap: 15px;
    padding-left: 4%;
    padding-right: 4%;
  }
  @media (max-width: 320px) {
    column-gap: 10px;
    padding-left: 0;
    padding-right: 0;
  }
`

const FeatureOfferingsBottomTitle = styled.h3`
  margin-top: 60px;
  margin-bottom: 35px;
  margin-left: 50px;
  @media (max-width: 1200px) {
    margin-left: 36px;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-left: 0;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {

    margin-top: 20px;
    margin-bottom: 25px;
  }
`

const FeatureOfferingsBottomItems = styled.p`
  margin-block-start: 0;
  margin-block-end: 0;
  display: inline-block;
  margin-bottom: 30px;
  
`

const FeatureOfferingsButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    @media (max-width: ${BREAKPOINTS.tbl}px) {
      flex-direction: column;
      align-items: center;
    }
`

const FeatureOfferingsButtonPrimary = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 60px;
  border: 1px solid var(--color-heading);
  background-color: var(--color-heading) !important;
  color: #fff !important;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff !important;
    color: var(--color-heading) !important;
  }
  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }

  
`


const FeatureOfferingsButtonSecondary = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 60px;
  margin-left: 20px;
  background-color: #fff;
  border: 1px solid var(--color-skobeloff);
  color: var(--color-skobeloff);
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;


  &:hover {
    color: var(--color-white) !important;
    background-color: var(--color-skobeloff);
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;

  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-left: 0;

    border: 1px solid;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }
  
`




/**
 * Renders the Sustainability Framework page.
 * Loads Shopify Buy Buttons after page load.
 */
const SustainabilityFrameworkPage = (props) => {
  const data = useStaticQuery(graphql`
    query FrameworkQuery {
      contentfulPageFramework {
        title
        seoTitle
        seoDescription
        openGraphImage {
          title
          gatsbyImageData(layout: FULL_WIDTH, quality: 85)
        }
        hero {
          heading
          subheading
      
         
        }
        heroSecondaryHeading
        heroSecondaryBody {
          heroSecondaryBody
        }
       
        firstFeatureSection {
          title
          titleAlignment
          columns
          infoCards {
            icon {
              file {
                url
              }
            }
            body {
              body
            }
            heading
            ctaText
            ctaDestination
            layout
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          ctaText
          ctaDestination
          secondCtaText
          secondCtaDestination
          body {
            body
          }
        }
        secondFeatureSection {
          bottomInformationFifthItem {
            bottomInformationFifthItem
          }
          bottomInformationFirstItem {
            bottomInformationFirstItem
          }
          bottomInformationFourthItem {
            bottomInformationFourthItem
          }
          bottomInformationSecondItem {
            bottomInformationSecondItem
          }
          bottomInformationSixthItem {
            bottomInformationSixthItem
          }
          bottomInformationThirdItem {
            bottomInformationThirdItem
          }
          bottomInformationTitle
          bottomInformationFifthEmoji
          bottomInformationFirstEmoji
          bottomInformationFourthEmoji
          bottomInformationSecondEmoji
          bottomInformationSixthEmoji
          bottomInformationThirdEmoji
          firstCtaText
          firstCtaDestination
          secondCtaText
          secondCtaDestination
          topInformationBody1
          topInformationBody2
          topInformationBody3
          topInformationTitle1
          topInformationTitle2
          topInformationTitle3
          topInformationEmoji3
          topInformationEmoji1
          topInformationEmoji2
          title
        }
        thirdFeatureSection {
          bottomInformationFifthItem {
            bottomInformationFifthItem
          }
          bottomInformationFirstItem {
            bottomInformationFirstItem
          }
          bottomInformationFourthItem {
            bottomInformationFourthItem
          }
          bottomInformationSecondItem {
            bottomInformationSecondItem
          }
          bottomInformationSixthItem {
            bottomInformationSixthItem
          }
          bottomInformationThirdItem {
            bottomInformationThirdItem
          }
          bottomInformationTitle
          bottomInformationFifthEmoji
          bottomInformationFirstEmoji
          bottomInformationFourthEmoji
          bottomInformationSecondEmoji
          bottomInformationSixthEmoji
          bottomInformationThirdEmoji
          firstCtaText
          firstCtaDestination
          secondCtaText
          secondCtaDestination
          topInformationBody1
          topInformationBody2
          topInformationBody3
          topInformationTitle1
          topInformationTitle2
          topInformationTitle3
          topInformationEmoji3
          topInformationEmoji1
          topInformationEmoji2
          title
        }
        fourthFeatureSection {
          card1 {
            ctaDestination
            ctaText
            date
            excerpt {
              excerpt
            }
            liveTrainingGuest
            liveTrainingTitle {
              liveTrainingTitle
            }
            title
            profilePic {
              title
              gatsbyImageData(width: 55)
            }
          }
          ctaDestination
          ctaText
          title
          card2 {
            ctaDestination
            ctaText
            date
            excerpt {
              excerpt
            }
            title
            liveTrainingGuest
            liveTrainingTitle {
              liveTrainingTitle
            }
            profilePic {
              title
              gatsbyImageData(width: 55)
            }
          }
          card3 {
            title
            liveTrainingTitle {
              liveTrainingTitle
            }
            liveTrainingGuest
            excerpt {
              excerpt
            }
            date
            ctaText
            ctaDestination
            profilePic {
              title
              gatsbyImageData(width: 55)
            }
          }
        }
        fifthFeatureSection {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        sixthFeatureSection {
          ctaText
          ctaDestination
          firstHeading
          firstParagraphContent {
            firstParagraphContent
          }
          fourthHeading
          fourthParagraphContent {
            fourthParagraphContent
          }
          secondHeading
          secondParagraphContent {
            secondParagraphContent
          }
          thirdHeading
          title
          thirdParagraphContent {
            thirdParagraphContent
          }
        }
      }
      contentfulSite {
        
        supporters {
          title
          logos {
            id
            title
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
          ctaText
          ctaDestination
        }
        
      } 
    }
  `)
  const openGraphImage = data.contentfulPageFramework.openGraphImage

  const heroHeading = data.contentfulPageFramework.hero.heading
  const heroSubheading = data.contentfulPageFramework.hero.subheading
  
  const heroSecondaryHeading = data.contentfulPageFramework.heroSecondaryHeading
  const heroSecondaryBody = data.contentfulPageFramework.heroSecondaryBody.heroSecondaryBody
  
  const partners = data.contentfulSite.supporters.logos
  const partnersTitle = data.contentfulSite.supporters.title
  const partnersCtaText = data.contentfulSite.supporters.ctaText
  const partnersCtaDestination = data.contentfulSite.supporters.ctaDestination
  const firstFeatureTitle = data.contentfulPageFramework.firstFeatureSection.title
  const firstFeatureTitleAlignment = data.contentfulPageFramework.firstFeatureSection.titleAlignment
  const firstFeatureColumns = data.contentfulPageFramework.firstFeatureSection.columns
  const firstFeatureInfoCards = data.contentfulPageFramework.firstFeatureSection.infoCards
  const firstFeatureCtaText = data.contentfulPageFramework.firstFeatureSection.ctaText
  const firstFeatureCtaDestination = data.contentfulPageFramework.firstFeatureSection.ctaDestination
  const firstFeatureSecondCtaText = data.contentfulPageFramework.firstFeatureSection.secondCtaText
  const firstFeatureSecondCtaDestination = data.contentfulPageFramework.firstFeatureSection.secondCtaDestination
  const firstFeatureBodyText = data.contentfulPageFramework.firstFeatureSection
  
  const secondFeatureTitle = data.contentfulPageFramework.secondFeatureSection.title
  const secondFeatureTopInfoTitle1 = data.contentfulPageFramework.secondFeatureSection.topInformationTitle1
  const secondFeatureTopInfoTitle2 = data.contentfulPageFramework.secondFeatureSection.topInformationTitle2
  const secondFeatureTopInfoTitle3 = data.contentfulPageFramework.secondFeatureSection.topInformationTitle3
  const secondFeatureTopInfoEmoji1 = data.contentfulPageFramework.secondFeatureSection.topInformationEmoji1
  const secondFeatureTopInfoEmoji2 = data.contentfulPageFramework.secondFeatureSection.topInformationEmoji2
  const secondFeatureTopInfoEmoji3 = data.contentfulPageFramework.secondFeatureSection.topInformationEmoji3
  const secondFeatureTopInfoBody1 = data.contentfulPageFramework.secondFeatureSection.topInformationBody1
  const secondFeatureTopInfoBody2 = data.contentfulPageFramework.secondFeatureSection.topInformationBody2
  const secondFeatureTopInfoBody3 = data.contentfulPageFramework.secondFeatureSection.topInformationBody3
  const secondFeatureBottomInfoTitle = data.contentfulPageFramework.secondFeatureSection.bottomInformationTitle
  const secondFeatureBottomInfoFirstEmoji = data.contentfulPageFramework.secondFeatureSection.bottomInformationFirstEmoji
  const secondFeatureBottomInfoFirst = data.contentfulPageFramework.secondFeatureSection.bottomInformationFirstItem.bottomInformationFirstItem
  const secondFeatureBottomInfoSecondEmoji = data.contentfulPageFramework.secondFeatureSection.bottomInformationSecondEmoji
  const secondFeatureBottomInfoSecond = data.contentfulPageFramework.secondFeatureSection.bottomInformationSecondItem.bottomInformationSecondItem
  const secondFeatureBottomInfoThirdEmoji = data.contentfulPageFramework.secondFeatureSection.bottomInformationThirdEmoji
  const secondFeatureBottomInfoThird = data.contentfulPageFramework.secondFeatureSection.bottomInformationThirdItem.bottomInformationThirdItem
  const secondFeatureBottomInfoFourthEmoji = data.contentfulPageFramework.secondFeatureSection.bottomInformationFourthEmoji
  const secondFeatureBottomInfoFourth = data.contentfulPageFramework.secondFeatureSection.bottomInformationFourthItem.bottomInformationFourthItem
  const secondFeatureBottomInfoFifthEmoji = data.contentfulPageFramework.secondFeatureSection.bottomInformationFifthEmoji
  const secondFeatureBottomInfoFifth = data.contentfulPageFramework.secondFeatureSection.bottomInformationFifthItem.bottomInformationFifthItem
  const secondFeatureBottomInfoSixthEmoji = data.contentfulPageFramework.secondFeatureSection.bottomInformationSixthEmoji
  const secondFeatureBottomInfoSixth = data.contentfulPageFramework.secondFeatureSection.bottomInformationSixthItem.bottomInformationSixthItem
  const secondFeatureFirstCtaText = data.contentfulPageFramework.secondFeatureSection.firstCtaText
  const secondFeatureFirstCtaDestination = data.contentfulPageFramework.secondFeatureSection.firstCtaDestination
  const secondFeatureSecondCtaText = data.contentfulPageFramework.secondFeatureSection.secondCtaText
  const secondFeatureSecondCtaDestination = data.contentfulPageFramework.secondFeatureSection.secondCtaDestination

  const thirdFeatureTitle = data.contentfulPageFramework.thirdFeatureSection.title
  const thirdFeatureTopInfoTitle1 = data.contentfulPageFramework.thirdFeatureSection.topInformationTitle1
  const thirdFeatureTopInfoTitle2 = data.contentfulPageFramework.thirdFeatureSection.topInformationTitle2
  const thirdFeatureTopInfoTitle3 = data.contentfulPageFramework.thirdFeatureSection.topInformationTitle3
  const thirdFeatureTopInfoEmoji1 = data.contentfulPageFramework.thirdFeatureSection.topInformationEmoji1
  const thirdFeatureTopInfoEmoji2 = data.contentfulPageFramework.thirdFeatureSection.topInformationEmoji2
  const thirdFeatureTopInfoEmoji3 = data.contentfulPageFramework.thirdFeatureSection.topInformationEmoji3
  const thirdFeatureTopInfoBody1 = data.contentfulPageFramework.thirdFeatureSection.topInformationBody1
  const thirdFeatureTopInfoBody2 = data.contentfulPageFramework.thirdFeatureSection.topInformationBody2
  const thirdFeatureTopInfoBody3 = data.contentfulPageFramework.thirdFeatureSection.topInformationBody3
  const thirdFeatureBottomInfoTitle = data.contentfulPageFramework.thirdFeatureSection.bottomInformationTitle
  const thirdFeatureBottomInfoFirstEmoji = data.contentfulPageFramework.thirdFeatureSection.bottomInformationFirstEmoji
  const thirdFeatureBottomInfoFirst = data.contentfulPageFramework.thirdFeatureSection.bottomInformationFirstItem.bottomInformationFirstItem
  const thirdFeatureBottomInfoSecondEmoji = data.contentfulPageFramework.thirdFeatureSection.bottomInformationSecondEmoji
  const thirdFeatureBottomInfoSecond = data.contentfulPageFramework.thirdFeatureSection.bottomInformationSecondItem.bottomInformationSecondItem
  const thirdFeatureBottomInfoThirdEmoji = data.contentfulPageFramework.thirdFeatureSection.bottomInformationThirdEmoji
  const thirdFeatureBottomInfoThird = data.contentfulPageFramework.thirdFeatureSection.bottomInformationThirdItem.bottomInformationThirdItem
  const thirdFeatureBottomInfoFourthEmoji = data.contentfulPageFramework.thirdFeatureSection.bottomInformationFourthEmoji
  const thirdFeatureBottomInfoFourth = data.contentfulPageFramework.thirdFeatureSection.bottomInformationFourthItem.bottomInformationFourthItem
  const thirdFeatureBottomInfoFifthEmoji = data.contentfulPageFramework.thirdFeatureSection.bottomInformationFifthEmoji
  const thirdFeatureBottomInfoFifth = data.contentfulPageFramework.thirdFeatureSection.bottomInformationFifthItem.bottomInformationFifthItem
  const thirdFeatureBottomInfoSixthEmoji = data.contentfulPageFramework.thirdFeatureSection.bottomInformationSixthEmoji
  const thirdFeatureBottomInfoSixth = data.contentfulPageFramework.thirdFeatureSection.bottomInformationSixthItem.bottomInformationSixthItem
  const thirdFeatureFirstCtaText = data.contentfulPageFramework.thirdFeatureSection.firstCtaText
  const thirdFeatureFirstCtaDestination = data.contentfulPageFramework.thirdFeatureSection.firstCtaDestination
  const thirdFeatureSecondCtaText = data.contentfulPageFramework.thirdFeatureSection.secondCtaText
  const thirdFeatureSecondCtaDestination = data.contentfulPageFramework.thirdFeatureSection.secondCtaDestination

const fourthFeatureSectionTitle =
  data.contentfulPageFramework.fourthFeatureSection.title
const fourthFeatureSectionCard1 =
  data.contentfulPageFramework.fourthFeatureSection.card1
  const fourthFeatureSectionCard2 =
  data.contentfulPageFramework.fourthFeatureSection.card2
  const fourthFeatureSectionCard3 =
  data.contentfulPageFramework.fourthFeatureSection.card3
const fourthFeatureSectionCtaText =
  data.contentfulPageFramework.fourthFeatureSection.ctaText
const fourthFeatureSectionCtaDestination =
  data.contentfulPageFramework.fourthFeatureSection.ctaDestination

  const fifthFeatureSectionLayout =
  data.contentfulPageFramework.fifthFeatureSection.layout
const fifthFeatureSectionImage =
  data.contentfulPageFramework.fifthFeatureSection.image
const fifthFeatureSectionTitle =
  data.contentfulPageFramework.fifthFeatureSection.title
const fifthFeatureSectionCards =
  data.contentfulPageFramework.fifthFeatureSection.featureCards
const fifthFeatureSectionCtaText =
  data.contentfulPageFramework.fifthFeatureSection.ctaText
const fifthFeatureSectionCtaDestination =
  data.contentfulPageFramework.fifthFeatureSection.ctaDestination


const sixthFeatureSectionFirstHeading =
  data.contentfulPageFramework.sixthFeatureSection.firstHeading
  const sixthFeatureSectionFirstBody =
  data.contentfulPageFramework.sixthFeatureSection.firstParagraphContent.firstParagraphContent
  const sixthFeatureSectionSecondHeading =
  data.contentfulPageFramework.sixthFeatureSection.secondHeading
  const sixthFeatureSectionSecondBody =
  data.contentfulPageFramework.sixthFeatureSection.secondParagraphContent.secondParagraphContent
  const sixthFeatureSectionThirdHeading =
  data.contentfulPageFramework.sixthFeatureSection.thirdHeading
  const sixthFeatureSectionThirdBody =
  data.contentfulPageFramework.sixthFeatureSection.thirdParagraphContent.thirdParagraphContent
  const sixthFeatureSectionFourthHeading =
  data.contentfulPageFramework.sixthFeatureSection.fourthHeading
  const sixthFeatureSectionFourthBody =
  data.contentfulPageFramework.sixthFeatureSection.fourthParagraphContent.fourthParagraphContent
const sixthFeatureSectionTitle =
  data.contentfulPageFramework.sixthFeatureSection.title
const sixthFeatureSectionCtaText =
  data.contentfulPageFramework.sixthFeatureSection.ctaText
const sixthFeatureSectionCtaDestination =
  data.contentfulPageFramework.sixthFeatureSection.ctaDestination

  const seoTitle = get(this, 'props.data.contentfulPageFramework.seoTitle')
  const seoDescription = get(this, 'props.data.contentfulPageFramework.seoDescription')


  return (
    <Layout location={props.location}>
      <SEO
        pageTitle={seoTitle}
        seoDescription={seoDescription}
        // ogImage={openGraphImage.gatsbyImageData.images.fallback.src}      
      />
      <div id="guide"></div>
      <Hero
       
        heading={heroHeading}
        subheading={heroSubheading}
     
      />

      
      <HeroSecondaryLanding 
        title={heroSecondaryHeading} 
        body={heroSecondaryBody} 
      
      />
      <SectionContainer>
        <FeaturesContainer width={12}>
            <FeaturesHeading textAlign={firstFeatureTitleAlignment}>
              {firstFeatureTitle}
            </FeaturesHeading>
            <FeaturesBody         
              dangerouslySetInnerHTML={{
              __html: firstFeatureBodyText.body.childMarkdownRemark.html,
              }} 
            />

            <FeaturesCardsContainer columns={firstFeatureColumns}>
              {firstFeatureInfoCards.map((infoCard) => (
                <InfoCardCentred
                  key={slugify(infoCard.heading)}
                  heading={infoCard.heading}
                  icon={infoCard.icon}
                  layout={infoCard.layout.toLowerCase()}
                  body={infoCard.body.body}
                />
              ))}
            </FeaturesCardsContainer>
            <InfoGridButtonContainer>
            {firstFeatureCtaText && (
            
                  <InfoGridButton  href={firstFeatureCtaDestination}
                  target="__blank"
                  rel="nofollow">
                  
                    {firstFeatureCtaText}
                    <Arrow />
                  </InfoGridButton>
                
    
          
            )}
            {firstFeatureSecondCtaText && (
              
              <InfoGridSecondaryButton  href={firstFeatureSecondCtaDestination}
              target="__blank"
              rel="nofollow">
              
                {firstFeatureSecondCtaText}
  
              </InfoGridSecondaryButton>
          

    
            )}
          </InfoGridButtonContainer>
          </FeaturesContainer>      
          </SectionContainer>
          <FeatureOfferingsSection>
    <FeatureOfferingsFlex width={12}>
      <FeatureOfferingsTitle>{secondFeatureTitle}</FeatureOfferingsTitle>

        <FeatureOfferingsTopInfoBar>
          
            <FeatureOfferingsTopCol>
             <FeatureOfferingsTopEmojis className="first-top-emoji">{secondFeatureTopInfoEmoji1}</FeatureOfferingsTopEmojis>

                <FeatureOfferingsTopTitle>{secondFeatureTopInfoTitle1}</FeatureOfferingsTopTitle>
             
                <FeatureOfferingsTopBody>{secondFeatureTopInfoBody1}</FeatureOfferingsTopBody>
            </FeatureOfferingsTopCol>
           

              <FeatureOfferingsTopCol>
                <FeatureOfferingsTopEmojis>{secondFeatureTopInfoEmoji2}</FeatureOfferingsTopEmojis>

                  <FeatureOfferingsTopTitle>{secondFeatureTopInfoTitle2}</FeatureOfferingsTopTitle>
            
                  <FeatureOfferingsTopBody>{secondFeatureTopInfoBody2}</FeatureOfferingsTopBody>
              </FeatureOfferingsTopCol>
           

            <FeatureOfferingsTopCol>
              <FeatureOfferingsTopEmojis>{secondFeatureTopInfoEmoji3}</FeatureOfferingsTopEmojis>

                <FeatureOfferingsTopTitle>{secondFeatureTopInfoTitle3}</FeatureOfferingsTopTitle>
            
                <FeatureOfferingsTopBody>{secondFeatureTopInfoBody3}</FeatureOfferingsTopBody>
            </FeatureOfferingsTopCol>
        </FeatureOfferingsTopInfoBar>

        <FeatureOfferingsBottomTitle>{secondFeatureBottomInfoTitle}</FeatureOfferingsBottomTitle>

        <FeatureOfferingsBottomInfoBar>

            <FeatureOfferingsBottomRow>
            <FeatureOfferingsBottomEmojis>
              {secondFeatureBottomInfoFirstEmoji}
              </FeatureOfferingsBottomEmojis>
              <FeatureOfferingsBottomItems>{secondFeatureBottomInfoFirst}</FeatureOfferingsBottomItems>
              <FeatureOfferingsBottomEmojis>
              {secondFeatureBottomInfoSecondEmoji}
              </FeatureOfferingsBottomEmojis>
              <FeatureOfferingsBottomItems>{secondFeatureBottomInfoSecond}</FeatureOfferingsBottomItems>
            </FeatureOfferingsBottomRow>

            <FeatureOfferingsBottomRow>
              <FeatureOfferingsBottomEmojis>
              {secondFeatureBottomInfoThirdEmoji}
                </FeatureOfferingsBottomEmojis>
              <FeatureOfferingsBottomItems>{secondFeatureBottomInfoThird}</FeatureOfferingsBottomItems>
              <FeatureOfferingsBottomEmojis>
              {secondFeatureBottomInfoFourthEmoji}
                </FeatureOfferingsBottomEmojis>
                <FeatureOfferingsBottomItems>{secondFeatureBottomInfoFourth}</FeatureOfferingsBottomItems>
              </FeatureOfferingsBottomRow>
              <FeatureOfferingsBottomRow>
                <FeatureOfferingsBottomEmojis>
                {secondFeatureBottomInfoFifthEmoji}
                </FeatureOfferingsBottomEmojis>
                <FeatureOfferingsBottomItems>{secondFeatureBottomInfoFifth}</FeatureOfferingsBottomItems>
                <FeatureOfferingsBottomEmojis>
                {secondFeatureBottomInfoSixthEmoji}
              </FeatureOfferingsBottomEmojis>
                <FeatureOfferingsBottomItems>{secondFeatureBottomInfoSixth}</FeatureOfferingsBottomItems>
           </FeatureOfferingsBottomRow>
        </FeatureOfferingsBottomInfoBar>

            <FeatureOfferingsButtonContainer>
                <FeatureOfferingsButtonPrimary href={secondFeatureFirstCtaDestination}
                        target="__blank"
                        rel="nofollow" >
    
                    {secondFeatureFirstCtaText}
             
                    <Arrow /> 
                </FeatureOfferingsButtonPrimary>
                {secondFeatureSecondCtaText &&
                  <FeatureOfferingsButtonSecondary     
                  href={secondFeatureSecondCtaDestination}
                  target="__blank"
                  rel="nofollow">
                     
                     {secondFeatureSecondCtaText}
        
                  </FeatureOfferingsButtonSecondary>
                 }
        </FeatureOfferingsButtonContainer>
    </FeatureOfferingsFlex>
    </FeatureOfferingsSection>
       
    <FeatureOfferingsSection id="experttraining">
    <FeatureOfferingsFlex width={12}>
      <FeatureOfferingsTitle>{thirdFeatureTitle}</FeatureOfferingsTitle>

        <FeatureOfferingsTopInfoBar>
          
            <FeatureOfferingsTopCol>
             <FeatureOfferingsTopEmojis className="first-top-emoji">{thirdFeatureTopInfoEmoji1}</FeatureOfferingsTopEmojis>

                <FeatureOfferingsTopTitle>{thirdFeatureTopInfoTitle1}</FeatureOfferingsTopTitle>
             
                <FeatureOfferingsTopBody>{thirdFeatureTopInfoBody1}</FeatureOfferingsTopBody>
            </FeatureOfferingsTopCol>
           

              <FeatureOfferingsTopCol>
                <FeatureOfferingsTopEmojis>{thirdFeatureTopInfoEmoji2}</FeatureOfferingsTopEmojis>

                  <FeatureOfferingsTopTitle>{thirdFeatureTopInfoTitle2}</FeatureOfferingsTopTitle>
            
                  <FeatureOfferingsTopBody>{thirdFeatureTopInfoBody2}</FeatureOfferingsTopBody>
              </FeatureOfferingsTopCol>
           

            <FeatureOfferingsTopCol>
              <FeatureOfferingsTopEmojis>{thirdFeatureTopInfoEmoji3}</FeatureOfferingsTopEmojis>

                <FeatureOfferingsTopTitle>{thirdFeatureTopInfoTitle3}</FeatureOfferingsTopTitle>
            
                <FeatureOfferingsTopBody>{thirdFeatureTopInfoBody3}</FeatureOfferingsTopBody>
            </FeatureOfferingsTopCol>
        </FeatureOfferingsTopInfoBar>

        <FeatureOfferingsBottomTitle>{thirdFeatureBottomInfoTitle}</FeatureOfferingsBottomTitle>

        <FeatureOfferingsBottomInfoBar>

            <FeatureOfferingsBottomRow>
            <FeatureOfferingsBottomEmojis>
              {thirdFeatureBottomInfoFirstEmoji}
              </FeatureOfferingsBottomEmojis>
              <FeatureOfferingsBottomItems>{thirdFeatureBottomInfoFirst}</FeatureOfferingsBottomItems>
              <FeatureOfferingsBottomEmojis>
              {thirdFeatureBottomInfoSecondEmoji}
              </FeatureOfferingsBottomEmojis>
              <FeatureOfferingsBottomItems>{thirdFeatureBottomInfoSecond}</FeatureOfferingsBottomItems>
            </FeatureOfferingsBottomRow>

            <FeatureOfferingsBottomRow>
              <FeatureOfferingsBottomEmojis>
              {thirdFeatureBottomInfoThirdEmoji}
                </FeatureOfferingsBottomEmojis>
              <FeatureOfferingsBottomItems>{thirdFeatureBottomInfoThird}</FeatureOfferingsBottomItems>
              <FeatureOfferingsBottomEmojis>
              {thirdFeatureBottomInfoFourthEmoji}
                </FeatureOfferingsBottomEmojis>
                <FeatureOfferingsBottomItems>{thirdFeatureBottomInfoFourth}</FeatureOfferingsBottomItems>
              </FeatureOfferingsBottomRow>
              <FeatureOfferingsBottomRow>
                <FeatureOfferingsBottomEmojis>
                {thirdFeatureBottomInfoFifthEmoji}
                </FeatureOfferingsBottomEmojis>
                <FeatureOfferingsBottomItems>{thirdFeatureBottomInfoFifth}</FeatureOfferingsBottomItems>
                <FeatureOfferingsBottomEmojis>
                {thirdFeatureBottomInfoSixthEmoji}
              </FeatureOfferingsBottomEmojis>
                <FeatureOfferingsBottomItems>{thirdFeatureBottomInfoSixth}</FeatureOfferingsBottomItems>
           </FeatureOfferingsBottomRow>
        </FeatureOfferingsBottomInfoBar>

            <FeatureOfferingsButtonContainer>
                <FeatureOfferingsButtonPrimary href={thirdFeatureFirstCtaDestination}
                        target="__blank"
                        rel="nofollow" >
    
                    {thirdFeatureFirstCtaText}
             
                    <Arrow /> 
                </FeatureOfferingsButtonPrimary>
                {thirdFeatureSecondCtaText &&
                  <FeatureOfferingsButtonSecondary     
                  href={thirdFeatureSecondCtaDestination}
                  target="__blank"
                  rel="nofollow">
                     
                     {thirdFeatureSecondCtaText}
        
                  </FeatureOfferingsButtonSecondary>
                 }
        </FeatureOfferingsButtonContainer>
    </FeatureOfferingsFlex>
    </FeatureOfferingsSection>

 
      {fourthFeatureSectionCard1 && 
        <FeatureLiveTrainingsSection>
          <FeatureLiveTrainingsContainer width={12}>
              <LiveTrainingTitle>{fourthFeatureSectionTitle}</LiveTrainingTitle>
              <LiveTrainingCardsContainer>
                  <LiveTrainingCard 
                    cardTitle={fourthFeatureSectionCard1.title}
                    sessionTitle={fourthFeatureSectionCard1.liveTrainingTitle.liveTrainingTitle}
                    profilePic={fourthFeatureSectionCard1.profilePic.gatsbyImageData}
                    picTitle={fourthFeatureSectionCard1.profilePic.title}
                    guestName={fourthFeatureSectionCard1.liveTrainingGuest}
                    dateTime={fourthFeatureSectionCard1.date}
                    excerpt={fourthFeatureSectionCard1.excerpt.excerpt}
                    ctaDestination={fourthFeatureSectionCard1.ctaDestination}
                    ctaText={fourthFeatureSectionCard1.ctaText}
                  />
                  <LiveTrainingCard 
                    cardTitle={fourthFeatureSectionCard2.title}
                    sessionTitle={fourthFeatureSectionCard2.liveTrainingTitle.liveTrainingTitle}
                    profilePic={fourthFeatureSectionCard2.profilePic.gatsbyImageData}
                    picTitle={fourthFeatureSectionCard2.profilePic.title}
                    guestName={fourthFeatureSectionCard2.liveTrainingGuest}
                    dateTime={fourthFeatureSectionCard2.date}
                    excerpt={fourthFeatureSectionCard2.excerpt.excerpt}
                    ctaDestination={fourthFeatureSectionCard2.ctaDestination}
                    ctaText={fourthFeatureSectionCard2.ctaText}
                  />
                  <LiveTrainingCard 
                    cardTitle={fourthFeatureSectionCard3.title}
                    sessionTitle={fourthFeatureSectionCard3.liveTrainingTitle.liveTrainingTitle}
                    profilePic={fourthFeatureSectionCard3.profilePic.gatsbyImageData}
                    picTitle={fourthFeatureSectionCard3.profilePic.title}
                    guestName={fourthFeatureSectionCard3.liveTrainingGuest}
                    dateTime={fourthFeatureSectionCard3.date}
                    excerpt={fourthFeatureSectionCard3.excerpt.excerpt}
                    ctaDestination={fourthFeatureSectionCard3.ctaDestination}
                    ctaText={fourthFeatureSectionCard3.ctaText}
                  />
              </LiveTrainingCardsContainer>
              <LiveTrainingCTA href={fourthFeatureSectionCtaDestination} 
                      target="__blank"
                      rel="nofollow"
                      
                      >
                       
                      {fourthFeatureSectionCtaText}
             
                  <Arrow /> 
              </LiveTrainingCTA>
          </FeatureLiveTrainingsContainer>
      </FeatureLiveTrainingsSection>
      }
        <FeatureListWrapper id="services">
         <Container width={12}>
          <FeatureListTitle>
              {fifthFeatureSectionTitle}
          </FeatureListTitle>
          <FeatureList
            accentColor="var(--color-violet)"
            ctaText={fifthFeatureSectionCtaText}
            ctaDestination={
              fifthFeatureSectionCtaDestination
            }
            featureCardArr={fifthFeatureSectionCards.map(
              (card) => [card.heading, card.body ? card.body.body : undefined]
            )}
            image={fifthFeatureSectionImage}
            layout={fifthFeatureSectionLayout}
            offsetImage={true}
          />
        </Container>
      </FeatureListWrapper>

      <FeatureNumberedGrid
        title={sixthFeatureSectionTitle}
        heading1={sixthFeatureSectionFirstHeading}
        paragraph1={sixthFeatureSectionFirstBody}
        heading2={sixthFeatureSectionSecondHeading}
        paragraph2={sixthFeatureSectionSecondBody}
        heading3={sixthFeatureSectionThirdHeading}
        paragraph3={sixthFeatureSectionThirdBody}
        heading4={sixthFeatureSectionFourthHeading}
        paragraph4={sixthFeatureSectionFourthBody}
        CtaDestination={sixthFeatureSectionCtaDestination}
        CtaText={sixthFeatureSectionCtaText}
      />
  
        <LogoListWrapper>
          <LogoListContainer>
            <LogoGrid
              ctaDestination={partnersCtaDestination}
              ctaText={partnersCtaText}
              logos={partners}
              title={partnersTitle}
            />
          </LogoListContainer>
        </LogoListWrapper>
      
    </Layout>
  )
}

export default SustainabilityFrameworkPage
