import React from 'react'
import styled from 'styled-components'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import { BREAKPOINTS, ANIMATION, LAYOUT } from '../styles/cssVariables'
import { isAbsoluteUrl } from '../utils/utils'
import { Link } from 'gatsby'
import Container from './layout/container'

const NumberedGridSection = styled.section`
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }
  
`

const NumberedGridFlex = styled(Container)`

`

const Title = styled.h2`
    margin-bottom: 75px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    @media (max-width: ${BREAKPOINTS.medUp}px) {
        // margin-bottom: 27px;
        margin-bottom: 40px;
    }
    @media (max-width: ${BREAKPOINTS.tbl}px) {
    // margin-bottom: 20px;
    width: 100%;
    }
    @media (max-width: ${BREAKPOINTS.sml}px) {
    // margin-bottom: 15px;
        margin-bottom: 30px;
    }
`

const NumberedGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 200px;
    row-gap: 100px;
    @media (max-width: 1250px) {
        column-gap: 150px;
        row-gap: 75px;
    }
    @media (max-width: 1200px) {
        column-gap: 125px;
        row-gap: 75px;
    }
    @media (max-width: 1150px) {
        column-gap: 100px;
        row-gap: 75px;
    }
    @media (max-width: ${BREAKPOINTS.medUp}px) {
        // column-gap: 75px;
        grid-template-columns: 1fr;
        row-gap: 50px;
    }
`

const NumberedGridItmes = styled.div`
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-content: center;
    @media (max-width: ${BREAKPOINTS.medUp}px) {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: ${BREAKPOINTS.tbl}px) {
        width: 100%;
       
    }

`

const NumberedGridHeading = styled.h3`
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    @media (max-width: ${BREAKPOINTS.medUp}px) {
        // margin-left: unset;
        // margin-right: unset;
        margin-bottom: 15px;
    }
`

const NumberedGridParagraph = styled.p`
    margin-left: auto;
    margin-right: auto;
    @media (max-width: ${BREAKPOINTS.medUp}px) {
        text-align: center;
    }

`
const NumberedGridButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`

const NumberedGridButtonPrimary = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 75px;
  border: 1px solid var(--color-heading);
  background-color: var(--color-heading);
  color: #fff !important;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff;
    color: var(--color-heading) !important;
  }
  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 50px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 30px;

  }
}
  
`

const NumberedGridButtonPrimaryGatsbyLink = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 75px;
  background-color: var(--color-blue);
  color: #fff !important;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: var(--color-skobeloff);
  }
  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 50px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 30px;

  }
}
  
`
const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`





const FeatureNumberedGrid = (props) => {
  return (
    <NumberedGridSection>
        <NumberedGridFlex width={12}>
            <Title>{props.title}</Title>
            <NumberedGrid>
                <NumberedGridItmes>
                    <NumberedGridHeading>{props.heading1}</NumberedGridHeading>
                    <NumberedGridParagraph>{props.paragraph1}</NumberedGridParagraph>
                </NumberedGridItmes>
                <NumberedGridItmes>
                    <NumberedGridHeading>{props.heading2}</NumberedGridHeading>
                    <NumberedGridParagraph>{props.paragraph2}</NumberedGridParagraph>
                </NumberedGridItmes>
                <NumberedGridItmes>
                    <NumberedGridHeading>{props.heading3}</NumberedGridHeading>
                    <NumberedGridParagraph>{props.paragraph3}</NumberedGridParagraph>
                </NumberedGridItmes>
                <NumberedGridItmes>
                    <NumberedGridHeading>{props.heading4}</NumberedGridHeading>
                    <NumberedGridParagraph>{props.paragraph4}</NumberedGridParagraph>
                </NumberedGridItmes>
            </NumberedGrid>
            <NumberedGridButtonContainer>
        
                {isAbsoluteUrl(props.CtaDestination) ? (
                        <NumberedGridButtonPrimary       href={props.CtaDestination}
                        target="__blank"
                        rel="nofollow" >
                
                    {props.CtaText}
                    <Arrow /> 
                </NumberedGridButtonPrimary>
                    ) : (
                    <NumberedGridButtonPrimaryGatsbyLink to={props.CtaDestination}>
                        {props.CtaText}
                        <Arrow /> 
                    </NumberedGridButtonPrimaryGatsbyLink>
                )}
    
          
            </NumberedGridButtonContainer>
        </NumberedGridFlex>
    </NumberedGridSection>
  )
}



export default FeatureNumberedGrid
